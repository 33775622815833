<template>
  <layout-sub name="study" :pagination="{ current, total }">
    <base-news-list :list="list" />
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";
import BaseNewsList from "@/components/BaseNewsList";
import { fatchCurrentPage } from "@/api";

export default {
  name: "Study",
  components: { LayoutSub, BaseNewsList },
  data() {
    return {
      list: [],
      current: 1, // 当前页数
      total: 0, // 总页数
      pageSize: 10, // 每页条数
    };
  },
  mounted() {
    // 初使化当前页
    const { page } = this.$route.query;
    this.current = page || 1;

    // 获取当前页面内容
    this.init();
  },
  methods: {
    async init() {
      const { data, pageTotal } = await fatchCurrentPage({
        cid: 40,
        pageSize: this.pageSize,
        pageNo: this.current,
      });
      if (data) {
        this.list = data;
        this.total = pageTotal;
      }
    },
  },
};
</script>
